import { Button } from "../Button";
import { Container } from "../Container";

import "../../styles/hero.css";
import { siteSettings } from "../../settings";
import { DashboardShot } from "../features/DashboardShot";

export function Hero() {
  return (
    <Container className="relative  z-20 p-20 text-center lg:p-32">
      <h1 className="mx-auto max-w-4xl font-sans text-4xl font-semibold tracking-tight text-white sm:text-7xl hero_text ">
        <span> {""} Revolutionizing</span>
        <span> {""} your</span>
        <span> {""} digital</span>
        <span> {""} trading</span>
        <span> {""} experience </span>
      </h1>
      <p className="mx-auto mt-6 max-w-2xl text-[18px] tracking-tight text-[#CECFD1] hero_support">
        Seamlessly merging complexity with ease, Penta offers top-notch
        security, 24/7 support, and an intuitive platform for your tenacious
        trading and investment needs.
      </p>
      <div className="mt-10 flex justify-center gap-x-6 hero_buttons">
        <Button href={`${siteSettings.appLink}/register`}>Get started</Button>
        <Button href={`${siteSettings.appLink}/login`} variant="outline">
          <span>Log in</span>
        </Button>
      </div>
      {/* <DashboardShot /> */}
    </Container>
  );
}

// import Image from 'next/image
// import logoLaravel from '@/images/logos/laravel.svg'
// import logoMirage from '@/images/logos/mirage.svg'
// import logoStatamic from '@/images/logos/statamic.svg'
// import logoStaticKit from '@/images/logos/statickit.svg'
// import logoTransistor from '@/images/logos/transistor.svg'
// import logoTuple from '@/images/logos/tuple.svg'
